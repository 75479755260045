export * from './colors';
export * from './navebar';

export const GOOGLA_API_KEY = 'AIzaSyBheiY1amTUU_Y_RH_O-SllTrcyhvJ_ClE';

export const Location = {
  lng: 121.45310823866673,
  lat: 25.04686361394273,
};

export const EMAIL_SERVICE_ID = 'service_32hdmo8';
export const EMAIL_TEMPLATE_ID = 'template_3ktnn4h';
export const EMAIL_USER_ID = 'user_MsuM3ECGgrTkYZzJVS8QW';
